
import Vue from 'vue';
import { mapState } from 'vuex';
import DocumentCommunicationFilters from '@/views/communication/DocumentCommunicationFilters.vue';
import DocumentCommunicationOverviewItems from '@/views/communication/DocumentCommunicationOverviewItems.vue';
import Messenger from '@/views/communication/Messenger.vue';
import EmptyState from '@/components/Icons/EmptyState.vue';
import {
    getRecipientName,
    sortCommunications,
    sortMessagesInCommunications
} from '@/views/communication/communication.helpers';
import { getAllCommunications } from '@/services/communication';
import { getAccountantOfUserRequest } from '@/services/accountants';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { DocumentCommunicationData, IAccountingOfficeViewModel } from 'dexxter-types';

type MessagesFilter = 'all' | 'normal' | 'solved';

export default Vue.extend({
    name: 'DocumentCommunication',
    components: { EmptyState, DocumentCommunicationFilters, DocumentCommunicationOverviewItems, Messenger },
    data() {
        return {
            currentCommunication: null as DocumentCommunicationData | null,
            communications: [] as DocumentCommunicationData[],
            accountant: null as IAccountingOfficeViewModel | null,
            selectedFilter: 'all' as MessagesFilter,
            forceRerender: 0,
            refreshInterval: 30_000,
            refreshCommunicationsIntervalRef: null as ReturnType<typeof setInterval> | null
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        hasCommunications(): boolean {
            return this.communications.length > 0;
        },
        getCurrentDocumentId(): number | null {
            if (this.currentCommunication && this.currentCommunication.document) {
                return this.currentCommunication.document.id;
            }
            return null;
        },
        getPolymorphicDocumentId(): number | null {
            if (this.currentCommunication && this.currentCommunication.document) {
                return this.currentCommunication.document.documentId;
            }
            return null;
        },
        getCurrentRecipientName(): string {
            if (this.currentCommunication) {
                return getRecipientName(
                    this.currentCommunication.messages,
                    this.currentUserData.id,
                    this.accountant?.company.name
                );
            }

            return '';
        },
        emptyOverviewText(): string {
            if (this.selectedFilter === 'all') {
                return this.$t('communication.no_chats').toString();
            } else if (this.selectedFilter === 'solved') {
                return this.$t('communication.no_solved_chats').toString();
            } else {
                return this.$t('communication.no_unsolved_chats').toString();
            }
        },
        getSolvedParam(): boolean | undefined {
            if (this.selectedFilter === 'all') {
                return;
            } else {
                return this.selectedFilter === 'solved';
            }
        }
    },
    async created() {
        await Promise.all([this.fetchCommunications(true), this.fetchAccountant()]);

        this.refreshCommunicationsIntervalRef = setInterval(() => {
            this.fetchCommunications();
        }, this.refreshInterval);
    },
    beforeDestroy() {
        if (this.refreshCommunicationsIntervalRef) {
            clearInterval(this.refreshCommunicationsIntervalRef);
        }
    },
    methods: {
        async fetchCommunications(resetCurrentCommunicationIndex?: boolean): Promise<void> {
            try {
                const unsortedCommunications = await getAllCommunications(this.getSolvedParam);
                const unsortedCommunicationsWithSortedMessages = sortMessagesInCommunications(unsortedCommunications);

                this.communications = sortCommunications(unsortedCommunicationsWithSortedMessages);

                if (this.communications.length > 0 && resetCurrentCommunicationIndex) {
                    // When the page loads for the first time, we want to select the first communication
                    // This is also the case when the user clicks on a different filter
                    this.currentCommunication = this.communications[0];
                }
                this.forceRerender++;
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            }
        },
        async fetchAccountant(): Promise<void> {
            try {
                this.accountant = await getAccountantOfUserRequest();
            } catch (e: any) {
                if (e.response.status !== 404) {
                    apiErrorAndDisplay(e);
                }
            }
        },
        handleUpdateFilter(value: MessagesFilter): void {
            this.selectedFilter = value;
            this.fetchCommunications(true);
        },
        changeCurrentCommunication(communication: DocumentCommunicationData): void {
            this.currentCommunication = communication;
        }
    }
});
