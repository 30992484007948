
import Vue, { PropOptions } from 'vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
    name: 'DocumentCommunicationFilters',
    props: {
        selectedFilter: {
            type: String,
            required: true
        } as PropOptions<string>
    },
    data() {
        return {
            filterOptions: [
                { value: 'all', text: this.$t('communication.filters.all') },
                { value: 'normal', text: this.$t('communication.filters.open') },
                { value: 'solved', text: this.$t('communication.filters.solved') }
            ] as { value: 'all' | 'normal' | 'solved'; text: TranslateResult }[]
        };
    },
    methods: {
        emitUpdateFilterEvent(value: 'all' | 'normal' | 'solved'): void {
            this.$emit('update-filter', value);
        }
    }
});
