
import Vue, { PropOptions } from 'vue';
import { mapState } from 'vuex';
import { DocumentCommunicationData, IAccountingOfficeViewModel, MessageData } from 'dexxter-types';
import { getInitialsFromFullName, getRecipientName } from '@/views/communication/communication.helpers';

export default Vue.extend({
    name: 'DocumentCommunicationOverviewItems',
    props: {
        currentCommunication: {
            type: Object
        } as PropOptions<DocumentCommunicationData>,
        communications: {
            type: Array
        } as PropOptions<DocumentCommunicationData[]>,
        accountant: {
            type: Object,
            required: false
        } as PropOptions<IAccountingOfficeViewModel | null>,
        forceRerender: {
            type: Number,
            default: 0
        } as PropOptions<number>
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        selectedCommunicationDocumentId(): number | null {
            return this.currentCommunication.document ? this.currentCommunication.document.id : null;
        }
    },
    methods: {
        isCommunicationSelected({ document: { id } }: DocumentCommunicationData): boolean {
            return id === this.selectedCommunicationDocumentId;
        },
        emitUpdateCurrentCommunicationEvent(newCommunication: DocumentCommunicationData): void {
            this.$emit('update-current-communication', newCommunication);
        },
        getRecipientName(messages: MessageData[]): string | undefined {
            return getRecipientName(messages, this.currentUserData.id, this.accountant?.company.name);
        },
        getRecipientInitials({ messages }: DocumentCommunicationData): string | undefined {
            const nameOfRecipient: string | undefined = this.getRecipientName(messages);

            if (nameOfRecipient) {
                // TODO: search for a better way to get the initials, because I know that this is not the best way
                // TODO: extract into helper function
                return getInitialsFromFullName(nameOfRecipient);
            }
        },
        getLastMessage(messages: MessageData[]): MessageData {
            return messages[messages.length - 1];
        },
        communicationHasUnansweredMessage({ messages }: DocumentCommunicationData): boolean {
            const lastMessage = this.getLastMessage(messages);

            return lastMessage.senderId !== this.currentUserData.id;
        },
        getLastMessageSenderFirstName({ messages }: DocumentCommunicationData): string | undefined {
            const lastMessage = this.getLastMessage(messages);

            return lastMessage.senderName?.split(' ')[0];
        },
        getLastMessageBody({ messages }: DocumentCommunicationData): string {
            const lastMessage = this.getLastMessage(messages);

            return lastMessage.body;
        },
        communicationIsSolved({ solved }: DocumentCommunicationData): boolean {
            return solved;
        }
    }
});
